<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :on-save-listeners="['hoseUpdated']"
      class="px-4"
      v-on:complete="onHoseUpdate"
      :form-type="formType"
      :title="formTitle"
      :before-save="beforeSave"
      :item-transform="itemTransform"
      :btn-text="formTitle"

      :id="hoseId"
      v-on:itemUpdated="hoseUpdated"
      deleteBtnText="Archive"
      btn-icon="mdi-pencil"
      namespace="hosesEdit">
    <template #default="{item,errors,onUpdate}">
      <template v-if="formType === 'delete'">
        <div class="light px-3 mb-3">
          <hose-configuration-view v-on:hoseLoaded="onHoseLoaded" :hose-id="hoseId"></hose-configuration-view>
        </div>
        <v-alert type="error">
          Archiving of a tagged hose is irreversible, are you sure you want to do this?
        </v-alert>
      </template>
      <template v-else>

        <template v-if="clientAdmin">
          <item-list-loader :limit="10000" namespace="organisations" :custom-filters="{isDistributor: true}">
            <template #main="{items}">
              <v-select
                  :clearable="true"
                  label="Distributor"
                  v-model="item.distributorOrganisationId"
                  :items="items"
                  item-text="name"
                  item-value="id"
                  @input="onUpdate(item)"
                  :error="errors.distributorOrganisationId != null"
                  :error-messages="errors.distributorOrganisationId"/>
            </template>
          </item-list-loader>

          <item-list-loader :limit="10000" namespace="organisations"  :custom-filters="{isDistributor: false}">
            <template #main="{items}">
              <v-select
                  :clearable="true"
                  label="Customer"
                  v-model="item.customerOrganisationId"
                  :items="items"
                  item-text="name"
                  item-value="id"
                  @input="onUpdate(item)"
                  :error="errors.customerOrganisationId != null"
                  :error-messages="errors.customerOrganisationId"/>
            </template>
          </item-list-loader>

          <v-alert type="info">
            When updating the customer or distributor please ensure the following:
            <ul>
              <li>The customer is associated with the distributor</li>
              <li>Site and zone is set to one within the customer selected</li>
              <li>Any associated hose configurations are moved to the distributor if necessary</li>
            </ul>
          </v-alert>

        </template>

        <item-list-loader :on-new-data="(newZones) => onNewZones(newZones,item)" v-if="item.organisationZoneId || hadLocation || clientAdmin" :custom-endpoint="siteEndpoint(item)">
          <template #noResults>
            <v-alert type="error">
              No Sites and Zones found for this customer
            </v-alert>
          </template>
          <template #main="{items}">
            <v-select
                :clearable="false"
                label="Hose location"
                v-model="item.organisationZoneId"
                :items="items"
                item-text="label"
                item-value="value"
                @input="onUpdate(item)"
                :error="errors.organisationZoneId != null"
                :error-messages="errors.organisationZoneId"/>
          </template>
        </item-list-loader>

        <v-text-field
            v-if="item.organisationZoneId"
            label="Tagged hose reference"
            v-model="item.name"
            @input="onUpdate(item)"
            :error="errors.name != null"
            :error-messages="errors.name"
        />

        <v-text-field
            v-if="item.organisationZoneId"
            label="Installation date (dd/mm/yyyy)"
            v-model="item.installationDate"
            @input="onUpdate(item)"
            v-mask="'##/##/####'"
            :error="errors.installationDate != null"
            :error-messages="errors.installationDate"
        />

        <v-text-field
            v-if="item.organisationZoneId"
            label="Build date (dd/mm/yyyy)"
            v-model="item.buildDate"
            @input="onUpdate(item)"
            v-mask="'##/##/####'"
            :error="errors.buildDate != null"
            :error-messages="errors.buildDate"
        />

        <v-text-field
            v-if="optionalFields.manufactureDate !== 1"
            label="Manufacture date (dd/mm/yyyy)"
            v-model="item.manufactureDate"
            @input="onUpdate(item)"
            v-mask="'##/##/####'"
            :error="errors.manufactureDate != null"
            :error-messages="errors.manufactureDate"
        />

        <v-checkbox
            label="Override hose inspection and replacement timelines"
            v-model="overrideHoseTimeline"/>

        <v-row v-if="overrideHoseTimeline">
          <v-col cols="12" :md="6">
            <v-text-field dense
                          label="Inspection timeline"
                          persistent-hint
                          type="number"
                          suffix="months"
                          hint="The frequency this hose requires inspections"
                          v-model="item.hoseInspectionMonths"
                          :error="errors.hoseInspectionMonths != null"
                          :error-messages="errors.hoseInspectionMonths"/>
          </v-col>
          <v-col cols="12" :md="6">
            <v-text-field dense
                          label="Replacement timeline"
                          persistent-hint
                          suffix="months"
                          type="number"
                          hint="Time from installation this hose will be due for replacement"
                          v-model="item.hoseReplacementMonths"
                          :error="errors.hoseReplacementMonths != null"
                          :error-messages="errors.hoseReplacementMonths"/>
          </v-col>
        </v-row>
      </template>
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import HoseConfigurationView from "../../../components/hose-configurations/HoseConfigurationView";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {endpoints} from "@/store/network/hitag.client";

export default {
  name: "HoseEdit",
  components: {ItemListLoader, HoseConfigurationView, ApiSelectBox, FormLoader},
  data() {
    return {
      hose: null,
      overrideHoseTimeline: false,
      hadLocation: false,
      initialCheck: false
    }
  },
  props: {
    hoseId: null
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation', 'optionalFields']),
    formType() {
      if (this.$router.currentRoute.name === "HoseEdit") return 'edit';
      return "delete";
    },
    formTitle() {
      return this.$router.currentRoute.name === "HoseEdit" ? "Edit tagged hose" : "Archive tagged hose";
    }
  },
  methods: {
    onNewZones(newZones,item) {
      if(newZones.filter((zone) => zone.value === item.organisationZoneId).length === 0){
        item.organisationZoneId = null;
        item.installationDate = null;
      }
    },
    hoseUpdated(item) {
      if (!this.initialCheck && item !== null) {
        this.hadLocation = item.organisationZoneId !== null;
        this.initialCheck = true;
      }
      this.hose = item;
      if (item.hoseInspectionMonths || item.hoseReplacementMonths) {
        this.overrideHoseTimeline = true;
      }
    },
    siteEndpoint(item) {
      return endpoints.sites.getSitesForOrganisation(item.customerOrganisationId);
    },
    onHoseUpdate(hose) {
      console.log("HOSE UPDATED", hose);
      this.getItem(this.hoseId);
    },
    onHoseLoaded(hose) {
      console.log("HOSE EMITTED ", hose);
    },
    beforeSave(item) {
      if (!this.overrideHoseTimeline) {
        item.hoseInspectionMonths = null;
        item.hoseReplacementMonths = null;
      }
      if (item.manufactureDate) {
        item.manufactureDate = this.$options.filters.databaseDate(item.manufactureDate)
      }
      if (item.organisationZoneId === null) {
        item.installationDate = null;
        item.name = null;
      } else {
        if (item.installationDate) {
          item.installationDate = this.$options.filters.databaseDate(item.installationDate)
        }

      }
      if (item.buildDate) {
        item.buildDate = this.$options.filters.databaseDate(item.buildDate)
      }
      return item;
    },
    itemTransform(item) {
      if (item.manufactureDate) {
        item.manufactureDate = this.$options.filters.formatDate(item.manufactureDate)
      }
      if (item.installationDate) {
        item.installationDate = this.$options.filters.formatDate(item.installationDate)
      }
      if (item.buildDate) {
        item.buildDate = this.$options.filters.formatDate(item.buildDate)
      }
      console.log("ZONE ID", item.organisationZoneId);
      return item;
    },
    ...mapActions('hoses', ['getItem'])

  }
}
</script>

<style scoped>

</style>
